import React from 'react';
import { ReactComponent as Zhia } from './assets/icons/zhiaMiniLogo.svg';
import { ReactComponent as IconEmail } from './assets/icons/email.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <Zhia className="logo" />
          </div>
          <div className="title-holder">
            <h1>ZHIA RESOURCES</h1>
          </div>
          <div className="social">
            <a href="mailto:inquire@zhiaresources.com" title="Email">
              <IconEmail className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Website Coming Soon.</h1>
            <p>Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:inquire@zhiaresources.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span> Empowering Tomorrow's Technology </span>
        </div>
      </div>
    );
  }
}

export default App;